import {useRef, useState} from "react";
import styled from "styled-components";
import { Col, Img, Row,SegoeP,Container,Box,WrappedRow, OfferBox } from "../../style";
import { slimmyGummyLogo ,caIcon} from "../../constants/images";
import { Footer } from "../../components/layout/Footer";
import { useDeviceType } from "../../context/DeviceContext";
import { Notification } from "../../components/generic/Notification";

import { NavBox,PaymentForm,Offers,HeaderComponent } from "../../components/checkout";
import { SubHeader } from "../../components/checkout/subHeader";
import { MoneyBack30 } from "../../components/checkout/moneyBack30";
import { Testimonials } from "../../components/checkout/testimonials";




export const CheckoutPage = () => {
  const [selected,setSelected] = useState<number>(0)
  const paymentSection = useRef<HTMLDivElement | null>(null)
  const onSelect = (index:number)=>{
    paymentSection.current?.scrollIntoView({ behavior: 'smooth' });
    setSelected(index)
  }

  const {isMobile} = useDeviceType()
  

  
 
  return (
  <>
  <HeaderComponent/>
  <Section $backgroundColor1="#f7ffd3" $backgroundColor2="#fff">
    <SubHeader/>

    <Container $alignItems="start" $maxWidth="950px" padding="0" $gap="15px">
     
      <Col $maxWidth={isMobile?"100%":"68%"} gap="10px" margin="0px 0px 20px 0px">
        
      <NavBox/>
      <Offers currentIndex={selected} onSelect={onSelect}/>
      <Row>
          <OfferBox $background="#fff">

            <Testimonials />

          </OfferBox>
        </Row>
        <Row>
          <SegoeP color="#fd6f03" weight={800} margin="20px" $lineHeight={"22px"} $fontSize="18px">HURRY! CONFIRM YOUR ORDER NOW!</SegoeP>
        </Row>

      </Col>
      <Col $maxWidth={isMobile?"100%":"30%"}>
        <PaymentForm currentIndex={selected} ref={paymentSection}/>
      </Col>

      
      
    </Container>
    <Footer/>
  </Section>

  <Notification/>
 
  
  
  </>
  
    
  );
};


const Section = styled.div<{ $backgroundColor1: string, $backgroundColor2?: string, $zIndex?: number, padding?: string }>`
  background:${props => props.$backgroundColor2?"linear-gradient(to bottom, "+props.$backgroundColor1+", "+props.$backgroundColor2+")":props.$backgroundColor1};
  padding:${props => props.padding || '10px 0px'};
  position: relative;
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  z-index: ${props => props.$zIndex !== undefined ? props.$zIndex : 0};

`;



