import { useDeviceType } from "../../context/DeviceContext";
import { Col, SegoeP, WrappedRow, Img,Row } from "../../style"
import { guarantee, Images } from "../../constants/images";
import styled from "styled-components";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    name: "Ahron B.",
    position: Images.Rate1,
    photo: Images.Person1,
    text:
      "Hey everyone, just wanted to share my thoughts on BioKetix ACV. I went for the 'Redefine' package, and honestly, it's been pretty good. The taste is a bit weird at first, but you get used to it. You can tell it's got some good stuff inside, like minerals and vitamins. Anyway, it's helped me shed some pounds, just in time for summer. Oh, and bonus - got the fourth month free with my subscription. Can't complain about that! Give it a shot if you're looking for something easy to add to your routine."
  },
  {
    name: "Eileen C.",
    position: Images.Rate2,
    photo: Images.Person2,
    text:
      "A heartfelt thank you to the BioKetix team for supporting me throughout this 7-month journey. Choosing the Redefine Elite pack has been life-changing. While I prefer to keep my weight loss private, the transformation goes beyond the physical—my mindset, confidence, and even my career have seen incredible improvements. Thanks to BioKetix, I've achieved goals I never thought possible. Forever grateful to this amazing team!"
  },
  {
    name: "Amber W.",
    position: Images.Rate3,
    photo: Images.Person3,
    text:
      "I was a bit skeptical at first, but my friend recommended it, so I gave it a shot, and let me tell you, it's been a game-changer. I managed to lose a whopping 17.6 lbs in just 10 weeks! It's been pretty massive for me. Now, I'll be real - the only downside was that it took them about 7 business days to arrive for the first time. But hey, considering the results I've seen, it was worth the wait! If you're on the fence about trying it out, I say go for it. It worked wonders for me, and I couldn't be happier with the results."
  }
];


export const Testimonials = () => {
  const { isMobile } = useDeviceType()
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Col justify="flex-start">
      <Head>
        <Col align="center"><SegoeP $lineHeight="16px" color="#fff" $fontSize={isMobile ? "17px" : "27px"} margin="10px" weight={700}>Testimonials</SegoeP></Col>
      </Head>
      <Col margin="0px 0px 30px 0px">
        <CarouselContainer>

        <StyledSlider {...carouselSettings}>
          {
            testimonials.map((t, index) => {
              return (
                <CarouselSlide key={index} className="slide">
                  
                 <Col>
                 
                    <Img src={t.photo} width={"100px"} />
                 
                    <SegoeP direction="justify" weight={700} margin="10px" $lineHeight={"15px"} $fontSize="15px">{t.name}</SegoeP>
                    <Img src={t.position} width={"120px"} />

                  <Col>
                    <SegoeP direction="justify" margin="10px" $lineHeight={"22px"} $fontSize="15px">{t.text}</SegoeP>
                  </Col>
                 
                 </Col>

                 
                  
                </CarouselSlide>)
            })
          }

        </StyledSlider>


        </CarouselContainer>
        
        


      </Col>


    </Col>

  )

}

const Head = styled.div<{ justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' }>`
  display: flex;
  width: 100%;
  justify-content:${props => props.justify || 'center'};
  border-radius: 5px 5px 0px 0px;
  background:#6b0d7d;
  padding-left:10px;
  box-sizing: border-box;
  
`;


const CarouselContainer = styled.div`
  width: 85%;
  margin: 0 auto;
`;

const StyledSlider = styled(Slider)`
  .slick-slide .slide {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-sizing: border-box;
    
  }

  .slick-prev:before, .slick-next:before {
    color: #6b0d7d;
  }
  
  .slick-dots li button:before {
    color: #e91a1a;
    
  }
  
  .slick-dots li.slick-active button:before {
    color: #6b0d7d;
    
  }
`;

const CarouselSlide = styled.div`
  padding: 10px;
`;

const CarouselImage = styled.img`
  max-width: 50px;
  border-radius: 50px;
`;