import { Container,Col,WrappedRow,AbsoluteImg,SegoeP,Color,Row,Img,BulletsArea,Bullet,RobertoTitle,OswaldTitle,SegoeTitle, Btn } from "../../style";
import { model,arrowImage,logoJu,securityIcons,bottleImage,arrow1Icon,guaranteeIcon,ingredientsIcon,usa } from "../../constants/images";
import styled from "styled-components";
import { useDeviceType } from "../../context/DeviceContext";
import { forwardRef } from "react";
import { OrderForm } from "./OrderForm";






export const Main = forwardRef<HTMLDivElement>((props,ref) => {
  const {isMobile} = useDeviceType() 

  return  (
  
  
    <Section $isMobile={isMobile} className="sec1">
      <Container padding="0">
        <WrappedRow>
          <Col align="start" $maxWidth={isMobile?"100%":"50%"}>
  
          <Img width={"210px"} src={logoJu}/>
          <RobertoTitle $isMobile={isMobile} $fontSize={110}>MELT FAT <i>FAST!</i></RobertoTitle>
          <OswaldTitle $isMobile={isMobile} $fontSize={55} $align="start" $lineHeight={82}>WITHOUT DIET OR EXERCISE</OswaldTitle>
          <OswaldTitle $isMobile={isMobile} $fontSize={40} $align="start" color="#7fc407" >Powerful New Formula Triggers Fat-Burning Ketosis!</OswaldTitle>
  
          <BulletsArea $isMobile={isMobile}>
            <Bullet>Burn Fat for Energy not Carbs</Bullet>
            <Bullet>Release Fat Stores</Bullet>
            <Bullet>Increase Energy Naturally!</Bullet>
            <Bullet>Love the Way You Feel!</Bullet>
          </BulletsArea>
  
          <Row>
            <Col justify="flex-start" >
              <Img width={"100%"} src={arrowImage} />
              <Img  width={isMobile?"90%":"60%"} src={securityIcons} margin="-20px 0px 0px 0px" />
            </Col>
            <Col $maxWidth="25%">
            <Img width={"100%"} src={bottleImage}  margin="-45px 0px 0px 0px"/>
            </Col>
            
              <Col margin={"-100px 0px 0px 0px"} $maxWidth={isMobile?"24%":"20%"} justify="flex-start">
                <Img width={"100%"}  src={ingredientsIcon} margin="-20px 0px 0px 0px" />
                <Img width={"100%"}  src={guaranteeIcon} margin="-20px 0px 0px 0px"/>
                <Img width={"95%"}  src={usa} margin="-12px 0px 0px 0px"/>
              </Col>
              
  
          </Row>
          
          </Col>
          {
            !isMobile && 
            <Col className="cont" justify="center" $maxWidth="40%" align="end" margin="0px 15px" padding="0px 30px">
                  <OrderForm ref={ref}/>
                  
                  
              
            </Col>
          }
          
  
        </WrappedRow>
  
  
      
      </Container>
      
  
  
    </Section>
    
    
    );
  


} )

const Section = styled.div<{$isMobile:boolean}>`

  display: flex;
  flex: 1;
  position: relative;
  padding: 10px;
  overflow: hidden;
  background: linear-gradient(${props=>props.$isMobile?'rgba(255, 255, 255, .5)':'transparent'}, rgba(255, 255, 255, .5)),  url(${model}); 
  background-position: calc(100% + 100px) center;
  background-size: auto, contain;
  background-repeat: no-repeat;


  
`;


