import { useLocation } from "react-router-dom";
import { CampaignsConfig } from "../constants/campaignsConfig";


export const useCampaignChecker = (cmpId?:string) => {

const location = useLocation();
const pathSegments = location.pathname.split('/').filter(Boolean);
const secondPathSegment = pathSegments[1]; 
let campaignId: string | undefined;
if(cmpId){
  campaignId = cmpId
}
else if (secondPathSegment in CampaignsConfig) {
  campaignId = CampaignsConfig[secondPathSegment as keyof typeof CampaignsConfig];
} else {
  campaignId = CampaignsConfig.default;
}
   
  
    return {campaignId};
  };
  