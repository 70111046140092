import { Step1Page } from './Step1'
import { CheckoutPage } from './Checkout'
import ThankUPage from './ThankU'
import { Route, Routes } from 'react-router-dom'
import React from 'react'

export const V1Routes = () => {
    console.log("v1 route")
    return (
        <Routes>
            <React.Fragment key={"v1"}>
                <Route path={`/`} element={<Step1Page />} />
                <Route path={`/checkout`} element={<CheckoutPage />} />
                <Route path={`/thank-you`} element={<ThankUPage />} />

            </React.Fragment>
        </Routes>
    )
}
