import { useEffect } from "react";
import { RequestConfigs } from "../api";
import { useMarketing } from "../context/MarketingContext";


export const useTagHandler = async (requestType:RequestConfig,serverRes:ApiResponse | null) => {

    const {marketing} = useMarketing()

    const pushToDataLayer = (event:string, data:any) => {
    window.dataLayer.push({
        event: event,
        ...data,
    });
    };

    const handleRoutes = (serverRes:ApiResponse)=>{
        switch (requestType.url){
            case RequestConfigs.createLead.url:
                if(serverRes?.succeeded){
                    pushToDataLayer("lead-created",{...marketing})
                    
                }
                break;
            case RequestConfigs.createOrder.url:
                pushToDataLayer("checkout",{...marketing})
                console.log("checkout")
                const checkoutTs = localStorage.getItem('checkout');
                const checkoutUnique = checkoutTs?Number(checkoutTs)>Date.now()-(1000*60*60*24):true

                if (!checkoutUnique) {
                    localStorage.setItem('checkout', JSON.stringify(Date.now()));
                    console.log("checkout unique")
                    pushToDataLayer("checkout-unique",{...marketing})
                    
                    if(serverRes.errorCode===400){
                        pushToDataLayer("decline-unique",{...marketing})
                        console.log("decline unique")
                    } 
                } 
                
                  
                if(serverRes.errorCode===400){
                    pushToDataLayer("decline",{...marketing})
                    console.log("decline")
                } 
                
                
        }
    }

    useEffect(() => {
        if(serverRes){
            handleRoutes(serverRes)
        }
        
      }, [serverRes]);

    return null;

  };



