
import { Box, Row, SegoeP, Img, Col, WrappedRow } from "../../style"
import styled from "styled-components"
import { Offer } from "./Offer";
import { guarantee, SecureIcons } from "../../constants/images";
import { useCampaign } from "../../context/CampaignContext";
import { useOrder } from "../../context/OrderContext";
import { useState } from "react";
import { useDeviceType } from "../../context/DeviceContext";
import { MoneyBack30 } from "./moneyBack30";


interface OffersProps {
  currentIndex: number
  onSelect: (index: number) => void
}

export const Offers = ({ currentIndex, onSelect }: OffersProps) => {

  const { campaign } = useCampaign()
  const { isMobile } = useDeviceType()

  return (
    <>
      <Box $background="#fff">
        {
          campaign?.products.map(
            (product, index) => (
              <Offer
                key={index}
                campaignProduct={product}
                currency={campaign?.currencySymbol || "E"}
                onSelect={onSelect}
                index={index}
                currentIndex={currentIndex}

              />

            )
          )
        }

        <Row>
          <SecImg
            src={SecureIcons}
            width={isMobile ? "400px" : "600px"}
          />
        </Row>
      

      </Box>

    </>


  )
}


const SecImg = styled.img<{ margin?: string }>`
  height: auto;
  margin:${props => props.margin || '0px'};
  clip-path: polygon(10% 0, 90% 0, 90% 100%, 10% 100%);
`;


