import { useDeviceType } from "../../context/DeviceContext";
import { Col, SegoeP,WrappedRow,Img } from "../../style"
import { guarantee } from "../../constants/images";
import styled from "styled-components";

export const MoneyBack30 = ()=>{
    const {isMobile} = useDeviceType()
    return (
        <Col justify="flex-start">
            <Head>
            <Col  align="center"><SegoeP $lineHeight="16px" color="#fff" $fontSize={isMobile?"17px":"27px"} margin="10px" weight={700}>30 DAY MONEY BACK GUARANTEE</SegoeP></Col>
            </Head>
            <WrappedRow $reverse={true} $gap="0">
              <Col $maxWidth={isMobile?"100%":"20%"}>
                <Img src={guarantee} width={isMobile?"139px":"139px"}/>
              </Col>
              <Col $maxWidth={isMobile?"100%":"70%"}>
                <SegoeP margin="10px" $lineHeight={"22px"} $fontSize="15px">We are so confident in our products and services, that we back it with a 30 day money back guarantee. If for any reason you are not fully satisfied with our products, simply return the purchased products in the original container within 30 days of when you received your order. We will refund you 100% of the purchase price - with absolutely no hassle.</SegoeP>
              </Col>
            </WrappedRow>
          
            
          </Col> 
        
    )

}

const Head = styled.div<{justify?:'center' | 'flex-start' | 'flex-end' |'space-between'}>`
  display: flex;
  width: 100%;
  justify-content:${props => props.justify || 'center'};
  border-radius: 5px 5px 0px 0px;
  background:#6b0d7d;
  padding-left:10px;
  box-sizing: border-box;
  /* flex-wrap: wrap; */
  
`;
