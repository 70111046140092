import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { DeviceProvider } from './context/DeviceContext';
import { LoaderProvider } from './context/LoaderContext';
import { V1Routes } from './pages/v1/routes';
import { Step1Page } from './pages/v1/Step1';
import { CheckoutPage } from './pages/v1/Checkout';
import { MainPage } from './pages';
import ThankUPage from './pages/v1/ThankU';
import { CampaignProvider } from './context/CampaignContext';
import { OrderProvider } from './context/OrderContext';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4';
import { useEffect, useLayoutEffect } from 'react';
import { MarketingProvider } from './context/MarketingContext';
import { ClientProvider } from './context/ClientContext';
import { TermsPage } from './pages/legal/terms';
import { PrivacyPage } from './pages/legal/privacy';
import { V2Routes } from './pages/v2/routes';

ReactGA.initialize('G-PDH3S7YJL5');

const tagManagerArgs = {
  gtmId: 'GTM-W27NSK5H'
}
TagManager.initialize(tagManagerArgs)

interface WrapperProps{
  children:React.ReactElement
}
const Wrapper:React.FC<WrapperProps> = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 




function App() {
  
  window.dataLayer = window.dataLayer || [];
  useEffect(() => {
    ReactGA.send(
      {
        hitType: "pageview",
        page: window.location.pathname
      }
    )
  }, []);
  return (
    <>
      <DeviceProvider>
        <LoaderProvider>
          <ClientProvider>
          <CampaignProvider>
            <MarketingProvider>
              <OrderProvider>
                <BrowserRouter basename='/'>
                <Wrapper>
                    <Routes>
                      <Route path={"/"} element={<MainPage />} />
                      <Route path={`/us/terms`} element={<TermsPage />} />
                        <Route path={`/eu/terms`} element={<TermsPage />} />
                        <Route path={`/us/privacy`} element={<PrivacyPage />} />
                        <Route path={`/eu/privacy`} element={<PrivacyPage />} />
                      <Route path="/v1/*" element={<V1Routes />} />
                      <Route path="/v2/*" element={<V2Routes />} />
                      
                    </Routes>
                  </Wrapper>
                  
                </BrowserRouter>

              </OrderProvider>


            </MarketingProvider>

          </CampaignProvider>

          </ClientProvider>
          



        </LoaderProvider>

      </DeviceProvider>
    </>
  );
}

export default App;
