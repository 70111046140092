import { caIcon, slimmyGummyLogo } from "../../constants/images"
import { useDeviceType } from "../../context/DeviceContext"
import { Container,Col,WrappedRow,Img,SegoeP,Box } from "../../style"


export const SubHeader = ()=>{
    const {isMobile} = useDeviceType()
    return (
        <Container $maxWidth="950px" padding="0" $gap="15px">
     
     <Col $maxWidth={isMobile?"100%":"65%"}>
        <Box $noBorder={true}>
          <WrappedRow justify="space-between" className="wr" $gap="0">

          
          
          <Col $maxWidth={isMobile?"100%":"30%"}>
          <Img src={slimmyGummyLogo} width={"150px"}/>
          </Col>
          <Col $maxWidth={isMobile?"35%":"30%"}>
          <Img src={caIcon} width={"150px"}/>
          </Col>
          
            
            <Col $maxWidth={isMobile?"50%":"30%"}>
            <SegoeP $lineHeight="16px" margin="0" $fontSize="14px" weight={500}>Internet Exclusive Offers Available to {process.env.REACT_APP_MARKET} Residents Only</SegoeP>
            </Col>
            

            </WrappedRow>
        </Box>


      </Col>
      <Col $maxWidth="30%">
      </Col>

     
      

      
      
    </Container>

    )
}